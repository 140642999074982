/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 4/02/2019, 03:19:46 PM
    Author     : Christian Nieves Uribe
*/

@function rem($num) {
    @return #{strip-unit($num) / (strip-unit($font-base))+'rem'};
}

@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
        @return $number / ($number * 0 + 1);
    }

    @return $number;
}

@for $i from 10 through 50 {
    .font-#{$i}{
        font-size:($i/strip-unit($font-base))+rem; 
    }
}

@for $i from 1 through 20 {
    .h-#{$i*10}{height: ($i*10)+vh;}
    .w-#{$i*10}{width: percentage(($i*10)/100);}
}

.d-relative{position: relative;}
.pointer{
    &:hover{
        cursor: pointer;
    }
}

a.fx-fade{
    display: block;
        position: relative;
        overflow: hidden;
        z-index:0;
        /*        img{
                transition: all linear .3s;
                &:hover{
                    transform: scale(1.1);
                }
            }*/
        &:after{
            transition: all linear .3s;
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0px;
            left: 0px;
            background-color: rgba(#125c97,.6);
            z-index: 1;
            opacity: 0;
        }
        &:before{
            transition: all linear .3s;
            content: "Ver más";
            color: #FFF;
            font-size: 20px;
            font-weight: bold;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            text-shadow: 1px 2px 5px rgba(0, 0, 0, .4);
            z-index: 2;
            opacity: 0;
        }
        &.fx-top{
            &:after{top: -100%;}
            &:before{top: -110%;}
            &:hover{
                &:before,&:after{opacity: 1;}
                &:before{top:50%;}
                &:after{top:0%;}
            }
        }
        &.fx-bottom{
            &:after{top: 100%;}
            &:before{top: 110%;}
            &:hover{
                &:before,&:after{opacity: 1;}
                &:before{top:50%;}
                &:after{top:0%;}
            }
        }
        &.fx-left{
            &:after{left: -100%;}
            &:before{left: -110%;}
            &:hover{
                &:before,&:after{opacity: 1;}
                &:before{left:50%;}
                &:after{left:0%;}
            }
        }
        &.fx-rigth{
            &:after{left: 100%;}
            &:before{left: 110%;}
            &:hover{
                &:before,&:after{opacity: 1;}
                &:before{left:50%;}
                &:after{left:0%;}
            }
        }
        &.fx-fadeIn{
            &:hover{
                &:before,&:after{opacity: 1;}
            }
        }
}