
// Body
$body-bg: #f8fafc;
$speed-transition: .3s;

// Typography
@import url('https://fonts.googleapis.com/css?family=Lato:400,700|Raleway');
$font-family-sans-serif: 'Lato', sans-serif;
$font-base: 16px;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$gray:#999699;
$gray-dark:#333333;
$primary: #125c97;


$blue-ligth: #86D2DF;
$blue: #005995;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66D9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$gray: #BDBEBF;
