/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 4/02/2019, 03:27:31 PM
    Author     : Christian Nieves 
*/
body{font-family: 'Lato', sans-serif;}
p{font-size: rem(19px);}
h2{
    font-size: rem(46px);
    color: color('blue');
    font-weight: 900;
}
h1,h2{
    
}

.wrapper{
    .parallax{
        background-image: url("../../public/img/neuron1.jpg");
        background-attachment: fixed;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        &:not([class*="h-"]){height: 100vh;}
        &.first{
            height: calc(100vh - 157px);
        }
    }
}

.btn-more{
    transition: all linear $speed-transition;
    font-size: rem(15px);
    padding: 10px 20px;
    border-radius: 15px;
    background-color: color('blue');
    color: color('white');
    &:hover{
        background-color: lighten(color('blue'),10%);
        color: color('white');
        text-decoration: none;
    }
}

.thumbnails{
    li{
        width: 100%;
        text-align:center;
        img{max-height:189px;}
        h3{
            
            color: color('white');
            font-size: rem(24px);
            font-weight: 900;
            margin: 1rem 0;
        }
        p{
            color: color('white');
            font-size: rem(15px);
            font-weight: 400;
        }
        a{display: inline-block;}
    }
    &.testimonios{
        li{
            background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
            padding: 1rem;
            border-top: 1px solid rgba(255, 255, 255, 0.1);
            font-size: rem(15px);
            font-style: italic;
        }
    }
    @include media-breakpoint-up(md) {
        &.column-3 li{width:calc((100% / 3) - 2rem);}
        &.column-4 li{width:calc((100% / 4) - 2rem);}
    }
}

.carousel{
    h3{
        margin-top:.5rem;
        margin-bottom:.5rem;
        font-size: rem(18);
        text-align: center;
        color: #000;
    }
}

.slick-arrow{display: none !important;}
.slick-dots{
    li{
        button{
            &:before {font-size: 25px;}
        }
    }
}

.directorio-item{
    a,p,label{
        font-size: rem(18px);
        margin-bottom: 0px;
        line-height: 130%;
    }
    label{font-weight: bold;}
    a{color: color('primary');}
    margin-bottom: 1rem;
}

.list-sub{
    border-right: thin solid $gray;
    li{
        position: relative;
        padding-right: 1.5rem;
        &.active{
            &:after{
                content: "";
                display: block;
                position: absolute;
                width: 2px;
                height: 100%;
                background-color: color("blue");
                top: 0px;
                right: 0px;
            }
        }
    }
}

.list-news{
    li{
        a{
            font-size: rem(18);
            color: color("blue");
        }
    }
}

.news{
    p{font-size: rem(18); color: #000; margin-bottom: 0px;}
    span{color:color('blue'); font-size: rem(14); display: block;}
    label{font-size: rem(14); display: block;}
    a{
        float: right;
    }
}