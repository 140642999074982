
// Fonts
@import url('https://fonts.googleapis.com/css?family=Lato:400,700');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~slick-carousel/slick/slick';
@import '~slick-carousel/slick/slick-theme';
@import '~font-awesome/scss/font-awesome.scss';
@import '~@fancyapps/fancybox/dist/jquery.fancybox.min.css';

/* App */
@import '_utilities';
@import '_navbar';
@import '_app';

.fancybox-slide--iframe .fancybox-content {
    width  : 100%;
    height : 90%;
    max-width  : 800px;
    max-height : 500px;
    margin: 0;
}

.navbar-laravel {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}


