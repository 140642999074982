/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 4/02/2019, 02:29:31 PM
    Author     : Christian Nieves
*/

.navbar-container{
    >div:nth-child(1){
        img{
            transition: all linear $speed-transition*2;
            max-height: 55px;
        }
    }
    >div:nth-child(2){
        transition: all linear $speed-transition*2;
        margin-top: 1rem;
    }
    .navbar-nav{
        .nav-item{
            .nav-link{
                font-size: rem(12px);
                transition: all linear $speed-transition*2;
                text-transform: uppercase;
                color: color('blue');
                font-family:  Arial, Helvetica, sans-serif;
                padding: 22.5px 10px;
                letter-spacing: 2px;
                &:hover{
                    background-color: color('blue');
                    color: color('white');
                }
            }
            &.active{
                .nav-link{
                    background-color: color('blue');
                    color: color('white');
                }
            }
        }
    }
    @include media-breakpoint-up(md){
        &.sticky-on{
            >div:nth-child(1){
                img{
                    max-height: 25px;
                }
            }
            >div:nth-child(2){
                margin-top: .25rem;
            }
            .navbar-nav{
                .nav-item{
                    .nav-link{
                        padding: 10px 10px;
                    }
                }
            }
            
            .dropdown-submenu>div.dropdown-menu {
                top: calc(90px - 7px);
            }
        }
    }
}

.navbar-nav li:hover > div.dropdown-menu {
    display: block;
}
.dropdown-submenu {
    position:relative;
}
.dropdown-submenu>div.dropdown-menu {
    transition: all linear $speed-transition*2;
    /*background-color: #D9F0F4;*/
    background-color: color("gray");
    position: fixed !important;
    top: calc(157px - 7px);
    left: -1px;
    width: calc(100vw - 15px);
    min-height: 50px;
    ul{
        li{
            a{color: #212529; line-height: 200%; text-transform: uppercase;}
            &:hover a,&.active a{
                color: color("blue");
                text-decoration: none;
                cursor: pointer
            }
        }
    }
    div[id^='preview-']{
        transition: all linear $speed-transition;
        width: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        /*display: none;*/
        opacity: 0;
        &.active{opacity: 1;}
        img{max-height: 195px;}
    }
    @include media-breakpoint-up(md){
        .row{
            min-height: 200px;
        }
    }
    /*.d-flex{display: flex !important;}*/
}


    .sub-sub{
        transition: opacity linear .3s;
        position: absolute;
        width: 100%;
        top: 0px;
        left: 0px;
        opacity: 0;
        &.active{opacity: 1;}
    }
    
    @include media-breakpoint-up(md){
        .space-100{
            height: calc(100vh - 90px);
        }
    }